import 'jquery';

import 'swiper';
import '../style/bulma.sass';
import '../style/index.scss';

// Check for click events on the navbar burger icon
$('.navbar-burger').click(function () {
  // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
  $('.navbar-burger').toggleClass('is-active');
  $('.navbar-menu').toggleClass('is-active');
});
